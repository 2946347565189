import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { InfoCard } from 'component-library'
import researchContent from '../../content/research'
import LinkComponent from '../LinkComponent/LinkComponent'
import './ResearchDatasetCollections.scss'

const { title, description, callToAction } = researchContent.datasetCollections

export const DESKTOP_DATASET_CONTAINER_ID = `research-dataset-collections-desktop`
export const MOBILE_DATASET_CONTAINER_ID = `research-dataset-collections-mobile`

const CollectionCount = ({ count }: { count: number }) => {
  return count > 1 ? (
    <span className="bg-brand-blue-5 text-white h-8 w-8 flex rounded-full text-center border-grey-9 border">
      <span className="m-auto">{count}</span>
    </span>
  ) : null
}

const DatasetCollectionTag = () => (
  <span className="flex items-center gap-2 text-grey-9">
    <i className="bx bxs-folder"></i>{' '}
    <span className="text-footnote">Dataset collection</span>
  </span>
)

const ResearchDatasetCollections = ({
  featuredCollections,
}: {
  featuredCollections: Queries.GetFeaturedResearchDatasetsQuery['pennylaneCloud']['datasetCollections']
}) => {
  const [emblaRef] = useEmblaCarousel({ loop: false, dragFree: true }, [
    WheelGesturesPlugin({ forceWheelAccess: 'x' }),
  ])
  return (
    <div className="ResearchDataset">
      <div className="container">
        <h3 className="text-h3 mb-3">{title}</h3>
        <p className="text-s2 mb-6">{description}</p>
        <div
          className="ResearchDataset__Desktop"
          id={DESKTOP_DATASET_CONTAINER_ID}
        >
          {featuredCollections.map((collection) => (
            <LinkComponent
              href={`/datasets/collection/${collection.slug}` || ''}
              testId="research-dataset-tile"
              key={collection.slug}
              openInCurrentTab
            >
              <InfoCard
                tagsTopRight={
                  <CollectionCount count={collection.numFamilies || 0} />
                }
                label={collection.title}
                image={collection.previewImage}
                tag={<DatasetCollectionTag />}
              />
            </LinkComponent>
          ))}
        </div>
      </div>
      <div
        className="ResearchDatasetCarousel"
        ref={emblaRef}
        id={MOBILE_DATASET_CONTAINER_ID}
      >
        <div className="ResearchDatasetCarousel__container">
          {featuredCollections.slice(0, 4).map((collection) => (
            <div
              className="ResearchDatasetCarousel__slide"
              key={collection.slug}
            >
              <LinkComponent
                href={`/datasets/collection/${collection.slug}` || ''}
                testId="research-dataset-tile"
                openInCurrentTab
              >
                <InfoCard
                  tagsTopRight={
                    <CollectionCount count={collection.numFamilies || 0} />
                  }
                  label={collection.title}
                  image={collection.previewImage}
                  tag={<DatasetCollectionTag />}
                />
              </LinkComponent>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <LinkComponent
          href={callToAction.link}
          className="Bottom__link"
          testId="research-demos-cta"
        >
          {callToAction.label}
          <i className="bx bx-chevron-right" />
        </LinkComponent>
      </div>
    </div>
  )
}

export default ResearchDatasetCollections
