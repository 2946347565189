export default {
  meta_title: 'Features — PennyLane',
  meta_description:
    'PennyLane is a flexible and powerful open-source framework for quantum programming, packed full of easy-to-use features to help turn your research into results.',
  meta_image:
    'https://assets.cloud.pennylane.ai/pennylane_website/pages/shared/open_graph.png',
  hero: {
    title: `Quantum programming that sparks joy.`,
    description: `PennyLane is a flexible and powerful open-source framework for quantum programming, packed full of easy-to-use features to help turn your research into results.`,
    image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/features/hero.png`,
    button_link: {
      text: `Install PennyLane`,
      url: `/install`,
    },
    text_link: {
      text: `Get started with introductory tutorials`,
      url: `/search/?contentType=DEMO&categories=getting%20started`,
    },
  },
  newest_features: {
    title: `Newest PennyLane features`,
  },
  pennylane_features: {
    /* 
      The `id` field serves as an identifier for enabling navigation via hash links on the page. 
      By default, the title of the section gets converted into a slug that serves as the ID. 
      If you're planning to specify a custom ID, make sure to follow proper URL syntax rules.

      eg. - hello world -> hello-world
    */
    id: '',
    title: 'PennyLane features',
    steps: [
      {
        title: 'Program quantum computers',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/quantum_computers.png',
        description: `
- PennyLane integrates seamlessly with the Python ecosystem.
- Construct quantum circuits using an extensive range of available state preparations, gates and measurements.
- Create flexible quantum algorithms and trainable programs.
- Execute on the fastest simulators or on a wide range of hardware devices.
- Access power-user functionality, from mid-circuit measurements to error mitigation.

[Quantum computing demos](/search/?contentType=DEMO&categories=quantum%20computing)

[Quantum circuits quick start](https://docs.pennylane.ai/en/stable/introduction/circuits.html)
`,
      },
      {
        title: 'Integrate with machine learning',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/machine_learning.png',
        description: `
- Add quantum gradients to your toolchain with your choice of JAX, PyTorch, Keras, TensorFlow, or NumPy.
- Define, train, and test hybrid quantum-classical models with feature embedding templates, quantum aware optimizers, and more.
- Hardware-compatible gradients and higher-order derivatives lets you train any model, on any device.
- Use research-level tools, from quantum kernels to Fourier representations.

[Quantum machine learning demos](/search/?contentType=DEMO&categories=quantum%20machine%20learning)

[Quantum machine quick start](https://docs.pennylane.ai/en/stable/introduction/interfaces.html)
`,
      },
      {
        title: 'Master quantum algorithms',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/algorithm.png',
        description: `
- From NISQ use-cases like VQE to the fault-tolerant setting, PennyLane unlocks quantum algorithms for research and application.
- Use PennyLane to analyze performance, monitor resource use, and visualize circuit layouts.
- Access application-specific functionality for quantum chemistry and QAOA.
- Cut large circuits into smaller bitesize chunks to scale up your applications.
- Go beyond qubits with pulse-level and qutrit representations.

[Quantum chemistry demos](/search/?contentType=DEMO&categories=quantum%20chemistry)

[Quantum chemistry quick start](https://docs.pennylane.ai/en/stable/introduction/chemistry.html)
`,
      },
      {
        title: 'Quantum datasets',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/datasets.png',
        description: `
- Easily-accessible, high-quality datasets that catalyze the development of new algorithms and the improvement of older ones.
- Decrease time-to-research with pre-simulated datasets.
- Easy-to-browse datasets: find exactly what you are looking for.
- Contribute your own data.

[Browse the datasets](/datasets)

[Quantum datasets quick start](https://docs.pennylane.ai/en/stable/introduction/data.html)
`,
      },
      {
        title: 'Compilation and performance',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/compilation_performance.png',
        description: `
- Just-in-time compilation of hybrid quantum-classical workflows. Efficiently capture your whole program.
- Scale from CPU to GPU with high performance simulators.
- Access the fastest tools for differentiating quantum circuits.
- Decompose high-level quantum circuits into hardware-compatible gates.
- Easily install with pip, Conda, Spack, and Docker.

[Learn about Catalyst](https://docs.pennylane.ai/projects/catalyst/en/stable/)

[High performance simulators](/performance)
`,
      },
    ],
  },
  research: {
    title: `Get impactful research out faster.`,
    subtitle: `PennyLane lets you code like you research.`,
    icons: [
      {
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/abstractions.png',
        title: `Abstractions for every researcher`,
      },
      {
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/hackable_layers.png',
        title: `Every layer is hackable`,
      },
      {
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/differentiable_everywhere.png',
        title: `Differentiable everywhere`,
      },
      {
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/future_facing.png',
        title: `Future-facing capabilities`,
      },
      {
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/diverse_ecosystem.png',
        title: `Diverse ecosystem of plugins`,
      },
    ],
    cta_link: {
      text: ``,
      url: ``,
    },
  },
  sub_section: {
    title: `What is quantum differentiable programming?`,
    description: `Everything in PennyLane is differentiable, including quantum circuits. This unlocks a brand new paradigm — from tweaking parameters to optimizing circuit structures, design the quantum program to fit your needs!`,
    image:
      'https://assets.cloud.pennylane.ai/pennylane_website/pages/features/differentiable.svg',
    links: [], // example: [{link_text: 'quantum computers', link: '/qml/quantum-computing'}, {link_text: 'differentiable everywhere', link: '/research'}]
  },
}
