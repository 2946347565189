import Seo from '../../components/seo'
import ResearchHero from '../../components/ResearchHero/ResearchHero'
import ResearchPapers from '../../components/ResearchPapers/ResearchPapers'
import ResearchDemos from '../../components/ResearchDemos/ResearchDemos'
import ResearchContact from '../../components/ResearchContact/ResearchContact'
import ProcessSection from '../../components/ProcessSection/ProcessSection'
import ResearchDatasetCollections from '../../components/ResearchDatasetCollections/ResearchDatasetCollections'
import TestimonialCarousel from '../../components/TestimonialCarousel/TestimonialCarousel'
import researchContent from '../../content/research'
import featuresContent from '../../content/features'
import './research.scss'

const { meta_title, meta_description, meta_image, testimonials_section } =
  researchContent
const { pennylane_features } = featuresContent

const Research = ({
  pageContext,
}: {
  pageContext: {
    demos: Queries.GetFeaturedResearchDemosQuery['allMarkdownRemark']['nodes']
    datasetCollections: Queries.GetFeaturedResearchDatasetsQuery['pennylaneCloud']['datasetCollections']
  }
}) => {
  return (
    <div className="Research">
      <ResearchHero />
      <div id="researcher-testimonials">
        <TestimonialCarousel
          testimonials={testimonials_section.quotes}
          title={testimonials_section.title}
        />
      </div>
      <ResearchPapers />
      <ResearchDemos featuredDemos={pageContext.demos} />
      <ResearchContact />
      <div className="PennyLaneFeatures">
        <ProcessSection
          id={pennylane_features?.id}
          title={pennylane_features.title}
          steps={pennylane_features.steps}
          gaEventCategory="features"
        />
      </div>
      <ResearchDatasetCollections
        featuredCollections={pageContext.datasetCollections}
      />
    </div>
  )
}

export default Research

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
